<template>
    <div class="default-headerBar">
        <div class="Logo"><img class="img" src="@/assets/logo.png" alt=""> 助店通服务平台</div>
        <div class="tool-bar">
            <div class="profile-wrap">
                <div class="img-wrap">
                    <img src="@/assets/sideBar/missing-face.png" alt="">
                </div>
                <div class="companyName">
                    {{userInfo.nickname}}
                    <span v-if="userInfo.kf_level == 1">(管理员)</span>
                    <span v-if="userInfo.kf_level == 2">(客服)</span>
                    <span v-if="userInfo.kf_level == 3">(海报设计师)</span>
                    <span v-if="userInfo.kf_level == 4">(市场专员)</span>
                    <span v-if="userInfo.kf_level == 5">(全景管理员)</span>
                </div>
            </div>
            <div class="logout" @click="handleLogouts">
                <div class="off">退出登录 <i class="el-icon-switch-button"></i></div>
            </div>
        </div>
    </div>
</template>

<script>
import common from "@/utils/common.js"
export default {
    data() {
        return {
            userInfo: {}
        }
    },
    mounted(){
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    },
    methods: {
        handleLogouts(){
            this.$confirm('将退出当前账号, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                common.connect("/customerservicev1/auth/logout",{},(res)=>{
                    localStorage.clear()
                    //要使用location强制跳转
                    location.href="/";   
                });
            });
        }
    },
    computed: {
        adAccountName(){
            return this.$store.state.adAccountName
        }
    }
}
</script>

<style lang="scss">
.default-headerBar{
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    // border-bottom: 1px solid #ccc;
    // box-shadow: 0px 5px 10px -5px #ccc;   
    // background-color: #c6c6c6; 
    // background-image: linear-gradient(to right, #fff , #296BEF);
    background-color: $headerBgColor;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    position: fixed;
    z-index: 999;
    background-color: #fff;
    .Logo{
        width: 250px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        background-color: #0B1529; 
        // color: #00BCD4;
        color: $logoColor;
        // font-weight: 600;
    }
    .img{
      width: 35px;
      margin-right: 10px;
    }
    .tool-bar{
        height: 100%;
        display: flex;
        align-items: center;
        // border: 1px solid red;
        box-sizing: border-box;
        .profile-wrap{
            display: flex;
            align-items: center;
            .img-wrap{
                width: 23px;
                height: 23px;
                margin-right: 10px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .companyName{
                color: #000;
                // letter-spacing: 1px;
            }
        }
        .logout{
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 20px;
            font-size: 15px;
            cursor: pointer;
            .off{
                font-size: 15px;
                padding: 2px;
                color: #606266;
            }
        }
    }
}
</style>