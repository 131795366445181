<template>
    <div class="default-menuBar">  
        <el-menu
            :default-active="curretMenuIndex"
            router
            :unique-opened="true"  
            @select="selectIndex"          
        >
            <template v-for="(item) in menuList" >
                <el-submenu :index="item.index" v-if='item.sbuMenu' :key="item.id">
                    <template slot="title" >        
                        <i :class="item.icon"></i>      
                        <span>{{item.title}}</span>
                    </template>       
                    <div v-for="(subItem,subindex) in item.subs" :key="subindex">
                        <el-menu-item-group v-if="subItem.sbuMenu">
                            <el-menu-item :key="subindex" :index="subItem.index" style="padding-left:55px">{{subItem.title}}</el-menu-item>
                        </el-menu-item-group>  
                    </div>          
                                             
                </el-submenu>
                <!-- <el-menu-item v-else :index="item.index" :key="index">
                    <i :class="item.icon"></i>
                    <span slot="title">{{item.title}}</span>
                </el-menu-item> -->
            </template>
        </el-menu>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
    mounted () {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.menuList = this.$menu.storeMenu;
        /**
         * 权限控制
         * userInfo.kf_level == 1 管理员
         * userInfo.kf_level == 2 客服
         * userInfo.kf_level == 3 海报设计师
         * userInfo.kf_level == 4 市场专员
         * userInfo.kf_level == 5 全景管理员
         */
        this.menuList.forEach((v,i) =>{
            // console.log(v.title);
            if((
                userInfo.id == 6 
                || userInfo.id == 25 
                || userInfo.id == 26
                || userInfo.id == 30
                ) && v.title == '分账管理'
            ){
                v.sbuMenu = true
            }
            
            v.subs.forEach(v1 =>{
                if((
                    userInfo.id == 6 
                || userInfo.id == 25 
                || userInfo.id == 26
                || userInfo.id == 30
                ) && v1.title == '营收数据报表'){
                    v1.sbuMenu = true
                }
                
                if(userInfo.kf_level == 2){ // 客服
                    if(v.index == "DataReport"){  
                        if(v1.title != '客服数据报表' && v1.title != '功能使用报表'){
                            v1.sbuMenu = false
                        }
                    }else if(v.index == 'DataStatistics'){
                        if(v1.title == '企业数据' || v1.title == '品牌跟进记录'){
                            v1.sbuMenu = false
                        }
                    }else if(v.index == "PosterManagement" || v.index =='InvoiceManagement' 
                        || v.index == 'PanoramaManagement' || v.index == 'VideoTutorialManagement'){
                        v.sbuMenu = false
                    }
                } else if(userInfo.kf_level == 3){ // 海报设计师
                    if(v.index == "DataReport"){  
                        if(v1.title != '海报数据报表'){
                            v1.sbuMenu = false
                        }
                    }else if(v.type == 1 || v.index == 'PanoramaManagement' || v.index == 'VideoTutorialManagement'){
                        v.sbuMenu = false
                    }else if(v.index == 'BusinessManagement'){
                        if(v1.title == '客服绩效'){
                            v1.sbuMenu = false
                        }
                    }
                }else if(userInfo.kf_level == 4){ // 市场专员
                    if(v.index == "DataReport"){  
                        if(v1.title != '市场数据报表' && v1.title != '功能使用报表'){
                            v1.sbuMenu = false
                        }
                    }else if(v.index == 'DataStatistics'){
                        if(v1.title == '门店跟进记录' || v1.title == '品牌跟进记录' || v1.title == '门店列表' || v1.title == '账号列表'){
                            v1.sbuMenu = false
                        }
                    }else if(v.index == "UserManagement" || v.index == "PosterManagement"
                        || v.index =='InvoiceManagement' || v.index == 'PanoramaManagement'
                        || v.index == 'VideoTutorialManagement'){
                        v.sbuMenu = false
                    }else if(v.index == 'BusinessManagement'){
                        if(v1.title == '客服绩效'){
                            v1.sbuMenu = false
                        }
                    }
                }else if(userInfo.kf_level == 5){ // 全景管理员
                    if(v.index == "DataReport"){  
                        if(v1.title != '全景数据报表'){
                            v1.sbuMenu = false
                        }
                    }else if(v.index == 'DataStatistics' || v.index =='InvoiceManagement'
                        || v.index == "UserManagement" || v.index == "PosterManagement"
                        || v.index == 'VideoTutorialManagement'){
                        v.sbuMenu = false
                    }else if(v.index == 'BusinessManagement'){
                        if(v1.title == '客服绩效'){
                            v1.sbuMenu = false
                        }
                    }
                }
            })
        })
    },
    computed: {
      curretMenuIndex(){
          return this.$store.state.curretMenuIndex
      }
    },
    data() {
        return {
            menuList:[],
            // userInfo:this.$methods.getUserInfo(),
            defaultActive:""
        }
    },
    methods: {
        ...mapMutations(['setCurretMenuIndex']),
        selectIndex(index){
            this.setCurretMenuIndex(index)
        },
    }
}
</script>

<style lang="scss">
.default-menuBar{
    width: 250px;
    height: 100%;
    box-sizing: border-box;
    // box-shadow: 0px 5px 10px 0px #ECEEF1; 
    // border-right: 1px solid #ccc;
    // border: 1px solid red;
    // background-color: $sideBgColor;
    z-index: 98;
    background-color: #0B1529;  
    .profile-wrap{
        height: 220px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        .profile{
            box-sizing: border-box;
            img{
                border: 1px solid #E8E8E8;
                border-radius: 50px;
                padding: 3px 3px;
                box-sizing: border-box;                
                width: 80px;
                height: 80px;
                box-shadow: 0px 0px 10px #ccc;
            }
            .wechat-info{
                margin-top: 8px;
                text-align: center;
                .name{
                    font-weight: 600;
                    color: #2C2C2C;
                }
                .welcome{
                    margin-top: 5px;
                    font-size: 12px;
                    color: #9B9B9B;
                }
            }
        }
        &::after{
            content: '';
            position: absolute;
            height: 1px;
            width: 85%;
            background-color: #E0E0E0;
            bottom: 0;
            left: 20px;
        }
    }
    .el-menu{
        width: 100%;
        height: calc(100% - 220px);
        border-right:none;
        // border: 1px solid red;
        text-align: left;
        // padding-left: 10px;
        box-sizing: border-box;
        background-color: #0B1529;        
        .iconfont{
            margin-right: 15px;
            font-size: 18px;
            font-weight: 500;
            color: #fff;
        }       
        .el-menu-item{
            // color: #fff;
            // font-weight: 600;
            color: #909399;
            font-size: 15px;
        }     
        .el-submenu{
            .el-submenu__title{
                font-size: 15px;
                color: #909399;
                
            }
            .el-menu-item{
                font-size: 14px;
                color: #909399;
                
            }      
            // .el-menu-item-group{
            // }    
        } 
        .el-menu-item:hover{
            background-color: #182848;
            color: #909399;
            .iconfont{
                color: #909399;
            }
        }
        .el-submenu__title:hover{
            background-color: #182848;
            color: #909399;
            .iconfont{
                color: #909399;
            }        
        }          
        .el-menu-item:focus{
            color: #fff;
            background-color: #182848;
        }  
        .el-menu-item.is-active{
            color: #fff;
            background-color: #182848;
            i{
                color: #fff;
            }
        } 
        .el-submenu.is-active>.el-submenu__title{
            color: #fff;
            
            i{
                color: #fff;
            }
        }  
    }  
}


</style>